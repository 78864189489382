import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SearchInput from '@/src/components/SearchInput';
import ViewSwitcher from '@/src/components/ViewSwitcher';

const Wrapper = styled.div`
  /* width: 380px; */
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  & button {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const SearchInputWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  ${({ theme }) => theme.onSM(`
    width: calc(100% - 44px);
  `)}
`;

const SearchInputContainer = styled.div`
  width: 252px;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
  ${({ theme }) => theme.onSM(`
    width: 100%;
  `)}
`;

const SearchButton = styled.button`
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin-right: 20px;
`;

const SearchIcon = styled.span`
  font-size: 2.4rem;
  color: rgba(0, 0, 0, 0.54);
  display: inline-block;
  vertical-align: middle;
`;

const SwitcherWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 8px;
  border-left: 1px solid #ededed;
  ${({ theme }) => theme.onSM(`
      display: none;
  `)};
`;

const SearchBar = (props) => {
  const { toggleView, urlParams, pageName } = props;
  return (
    <Wrapper>
      <SearchInputWrapper>
        <SearchButton>
          <SearchIcon className='icon-search' />
        </SearchButton>
        <SearchInputContainer>
          <SearchInput pageName={pageName} defaultVal={urlParams.q} toggleView={toggleView} />
        </SearchInputContainer>
      </SearchInputWrapper>
      <SwitcherWrap>
        <ViewSwitcher pageName={pageName} />
      </SwitcherWrap>
    </Wrapper>
  );
};

SearchBar.defaultProps = {
  pageName: '',
};

SearchBar.propTypes = {
  toggleView: PropTypes.func.isRequired,
  urlParams: PropTypes.object.isRequired,
  pageName: PropTypes.string,
};
export default SearchBar;
