import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Place from '../components/Place';

const PlacesWrapper = styled.div``;

class Places extends PureComponent {
  render() {
    const { data, clickCallback } = this.props;
    return (
      <PlacesWrapper>
        {data.map((place, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Place place={place} clickCallback={clickCallback} key={i + place.company_id} />
        ))}
      </PlacesWrapper>
    );
  }
}

Places.defaultProps = {
  clickCallback: null,
};

Places.propTypes = {
  data: PropTypes.array.isRequired,
  clickCallback: PropTypes.func,
};

Places.displayName = 'SearchResultsPlaces';

export default Places;
