/* eslint-disable max-len */
import React from 'react';
import NextHead from 'next/head';
import { withTranslation } from 'react-i18next';
import { string, number, object } from 'prop-types';

const defaultTitle = {
  ru: 'Chronica.md - интерактивная карта исторического Кишинева.',
  ro: 'Chronica.md - harta interactiva a Chisinaului istoric',
  en: 'Chronica.md - harta interactiva a Chisinaului istoric',
};
const defaultDescription = {
  ru:
    'Chronica.md - интерактивная карта исторического Кишинева',
  ro:
    'Chronica.md - harta interactiva a Chisinaului istoric',
  en:
    'Chronica.md - harta interactiva a Chisinaului istoric',
};
const defaultOGURL = 'https://Chronica.md';
const defaultOGImage = '/static/images/map-og.png';
const defaultOGImageWidth = 600;
const defaultOGImageHeight = 315;
const defaultKeywords = {
  ru: 'поинт карта, карта кишинева point, point карта, карта кишинева поинт, карта поинт, point.md карта, поинт мд карта, поинт карта кишинева, point md карта, карта point, point карты, поинт мап, карты поинт, поинт карты, карта поинт мд',
  ro: 'point map, point harta, point.md harta, harta point, point md map, harta chisinau point, point carta, harta chisinau point.md, point karta, point map chisinau, point map moldova, harta point.md, karta point, point mapa, point map online',
  en: 'point map, point harta, point.md harta, harta point, point md map, harta chisinau point, point carta, harta chisinau point.md, point karta, point map chisinau, point map moldova, harta point.md, karta point, point mapa, point map online'
};

const Head = (props) => {
  const {
    i18n, title, keywords, description, url, ogImage, ogImageWidth, ogImageHeight 
  } = props;
  const lang = i18n.language;
  
  let alternate1 = 'ro';
  let alternate2 = 'en';

  if (lang === 'ro') {
    alternate1 = 'ru';
    alternate2 = 'en';
  }
  if (lang === 'en') {
    alternate1 = 'ru';
    alternate2 = 'ro';
  }
  return (
    <NextHead>
      <title>{title || defaultTitle[lang]}</title>
      <meta name='description' content={description || defaultDescription[lang]} />
      <meta name='keywords' content={keywords || defaultKeywords[lang]} />
      <meta property='og:title' content={title || defaultTitle[lang]} />
      <meta property='og:description' content={description || defaultDescription[lang]} />
      <meta property='og:site_name' content='Chronica.md' />
      <meta name='twitter:site' content={url || defaultOGURL} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image' content={ogImage || defaultOGImage} />
      <meta property='og:image' content={ogImage || defaultOGImage} />
      <meta property='og:image:width' content={ogImageWidth || defaultOGImageWidth} />
      <meta property='og:image:height' content={ogImageHeight || defaultOGImageHeight} />
      <link rel='alternate' hrefLang={alternate1} href={`https://Chronica.md/${alternate1}/`} />
      <link rel='alternate' hrefLang={alternate2} href={`https://Chronica.md/${alternate2}/`} />
    </NextHead>
  );
};

Head.defaultProps = {
  title: '',
  description: defaultDescription.ru,
  keywords: '',
  url: defaultOGURL,
  ogImage: defaultOGImage,
  i18n: {},
  ogImageWidth: defaultOGImageWidth,
  ogImageHeight: defaultOGImageHeight,
};

Head.propTypes = {
  title: string,
  description: string,
  keywords: string,
  url: string,
  ogImage: string,
  i18n: object,
  ogImageWidth: number,
  ogImageHeight: number,
};

export default withTranslation()(Head);
