/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Body = styled.div`
  padding: 0 0 13px;
  flex: 1;
  border-bottom: 1px solid #ededed;
`;

const PlaceWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.02);
  border-left: 3px solid #fff;
  &:last-child {
    & ${Body} {
      border-bottom: none;
    }
  }
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    border-left: 3px solid ${({ theme }) => theme.colors.accent};
  }
`;

const Item = styled.div`
  padding: 13px 0 0 20px;
  margin-left: -3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const IconWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  max-width: 44px;
  flex: 1;
  padding: 0 0 13px;
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 1.6rem;
  line-height: 24px;
  display: inline-block;
  background-color: #f7f7f7;
  text-align: center;
  border-radius: 100px;
  color: rgba(0, 0, 0, 0.54);
`;

const Name = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.6rem;
  line-height: 19px;
  margin: 0 0 4px;
`;

const Info = styled.p`
  color: rgba(0, 0, 0, 0.3);

  font-size: 1.3rem;
  line-height: 15px;
  margin: 0;
`;

const CategoryPlaceCompanies = (props) => {
  const { items, openPlace } = props;
  return items.map((item, i) => (
    <PlaceWrapper key={item.branch_id + i} onClick={() => openPlace(item, true)}>
      <Item>
        <IconWrap>
          <Icon className='icon-place' />
        </IconWrap>
        <Body>
          <Name>{item.brand}</Name>
          <Info>{item.address_text}</Info>
        </Body>
      </Item>
    </PlaceWrapper>
  ));
};

CategoryPlaceCompanies.propTypes = {
  items: PropTypes.array.isRequired,
  openPlace: PropTypes.func.isRequired,
};

export default CategoryPlaceCompanies;
