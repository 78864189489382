import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { changeURLPage, checkUserLogged } from '@/src/helpers';
import SearchBar from '@/src/components/SearchBar';
import SearchResults from '@/src/components/SidebarViews/SearchResults';
import SidebarHeader from '@/src/components/Sidebar/Header';
import AddPlace from '@/src/components/SidebarViews/AddPlace';
import { clearSearchResults } from '@/src/redux/actions';
import { TogglerAddPlaceCtx } from '@/src/contexts';

const SidebarToggler = styled.button`
  height: 50px;
  width: 62px;
  padding-right: 10px;
  text-align: right;
  display: none;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 0 100px 100px 0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 10px 0 rgba(0, 0, 0, 0.07);
  position: absolute;
  right: -33px;
  top: 8px;
  z-index: 1;
  ${({ theme }) => theme.onSM(`
    display:none !important;
  `)}
`;

const TogglerIcon = styled.span`
  font-size: 2.4rem;
  display: inline-block;
  &.is-hidden {
    transform: rotate(180deg);
  }
`;

const ViewsContainer = styled.div`
  transition: opacity 0.2s ease-in-out;
  height: 100%;
  ${({ theme }) => theme.onSM(`
    // height: calc(100% - 119px);
    height: 100%;
    &.is-hidden{
      display:none;
    }
  `)};
`;

const Wrapper = styled.div`
  padding: 8px 8px;
  display: inline-block;
  position: relative;
  height: auto;
  width: 100%;
  // overflow: hidden;
  left: 0;
  top: 0;
  z-index: 99999;
  &.is-full {
    background-color: #f5f5f5;
    height:100%;
    // height: calc(100vh - 112px);
    & + ${SidebarToggler} {
      display: block;
    }
  }
  ${({ theme }) => theme.onSM(`
    overflow: hidden;
    transition: background-color 0.2s ease-in-out;
    &.is-full {
      height: 100%;
      // height: calc(100vh - 4px);
    }
  `)}
`;

const SendFeedbackWrapper = styled.div`
  display:none;
  height: 44px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #FFFFFF;
  box-shadow: 0 -1px 4px 0 rgba(0,0,0,0.05), 0 0 2px 0 rgba(94,94,94,0.21);
  color: rgba(0,0,0,0.5);
  font-size: 1.3rem;
  padding: 13px 27px;
  z-index: 99999;
  ${({ theme }) => theme.onSM(`
    display:none !important;
  `)}
`;

const SendFeedbackBtn = styled.button`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 1.3rem;
`;

const Header = styled(SidebarHeader)``;

const Container = styled.div`
  position: absolute;
  // padding-bottom: 44px;
  top: 0;
  width: 394px;
  // height: 100%;
  max-height: 100%;
  max-width: 100%;
  transition: transform 0.3s ease-in-out;
  &.is-embed {
    display:none;
  }
  &.hideFeedBack{
    padding-bottom: 0px
  }
  &.is-full {
    height: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07), 0 3px 10px 0 rgba(0,0,0,0.07);
    & ${SendFeedbackWrapper} {
      display:block;
    }
  }
  &.is-hidden {
    transform: translateX(-100%);
  }
  ${({ theme }) => theme.onSM(`
    min-width: 100%;
    box-shadow:none;
    padding-bottom:0;
    &.transparent {
      height: 65px;
      box-shadow:none;
      & ${Header} {
        border-radius: 10px;
      }
      & ${Wrapper} {
        background-color: transparent;
          &:not(.searching) {
          height: 65px;
          // overflow: hidden;
          box-shadow: none;
          background-color: transparent;
            ${ViewsContainer} {
              opacity: 1;
            }
          }
      }
    }
  `)}
  & * {
    -webkit-overflow-scrolling: touch;
  }
`;

const Sidebar = (props) => {
  const {
    activeView, t, searchResults, urlParams, mapShow, actionClearSearchResults, children, searchShow
  } = props;
  const { embed, pageName, subpage } = urlParams;
  const [isHidden, toggleSidebar] = useState(false);
  const [showAddPlace, togglerAddPlace] = useState(false);
  const [addAddError, togglerAddError] = useState(false);
  const hasRes = !!searchResults.length;
  const showFeedback = false;
  const toggleView = (viewName) => {
    const nextView = viewName !== activeView ? viewName : '';

    let clearSearch = true;
    if ((!activeView && viewName.length) || nextView !== activeView) {
      actionClearSearchResults();
      clearSearch = true;
    }
    changeURLPage(nextView, clearSearch);
  };

  const backBtn = () => {
    togglerAddError(false);
    togglerAddPlace(false);
  };

  useEffect(() => {
    actionClearSearchResults();
  }, [activeView]);

  return (
    <Container
      className={classNames({
        'is-hidden': isHidden,
        'is-full': (!!children || searchShow || (!pageName && hasRes)),
        'transparent': mapShow,
        'hideFeedBack': !showFeedback,
        'is-embed': !!embed
      })}
    >
      <Wrapper
        className={classNames({
          'is-full searching': searchShow || (!pageName && hasRes),
          'is-full': !!children,
        })}
      >
        {/* <Header className={(searchShow || (!pageName && hasRes)) && 'is-search'}>
          <SearchBar toggleView={toggleView} pageName={pageName} urlParams={urlParams} />
        </Header> */}
        <ViewsContainer className={classNames({ 'is-hidden': mapShow })}>
          {showAddPlace && <AddPlace addError={addAddError} BackBtn={backBtn} />}
          <SearchResults searchShow={searchShow} />
          <TogglerAddPlaceCtx.Provider value={{ togglerAddPlace, togglerAddError }}>
            {children}
          </TogglerAddPlaceCtx.Provider>
        </ViewsContainer>
      </Wrapper>
      <SidebarToggler
        type='button'
        data-rh-at='right'
        data-rh={isHidden ? t('Show sidebar') : t('Hide sidebar')}
        onClick={() => toggleSidebar(!isHidden)}
      >
        <TogglerIcon className={`${isHidden && 'is-hidden'} icon-arrow_left`} />
      </SidebarToggler>
      {showFeedback && (
        <SendFeedbackWrapper>
          {t('Not found something ?')}
          <SendFeedbackBtn onClick={() => {
            if (!checkUserLogged()) return;
            togglerAddError(true);
            togglerAddPlace(true);
          }}
          >
            {t('Tell us')}
          </SendFeedbackBtn>
        </SendFeedbackWrapper>
      )}
    </Container>
  );
};

Sidebar.defaultProps = {
  searchResults: [],
  mapShow: false,
  searchShow: false,
  children: null,
  activeView: ''
};

Sidebar.propTypes = {
  searchResults: PropTypes.array,
  urlParams: PropTypes.object.isRequired,
  actionClearSearchResults: PropTypes.func.isRequired,
  activeView: PropTypes.string,
  t: PropTypes.func.isRequired,
  mapShow: PropTypes.bool,
  searchShow: PropTypes.bool,
  children: PropTypes.node
};

const mapStateToProps = state => ({
  mapShow: state.map.mapShow,
  searchShow: state.search.searchShow,
  searchResults: state.search.results,
});

const mapDispatchToProps = dispatch => ({
  actionClearSearchResults: () => dispatch(clearSearchResults()),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
