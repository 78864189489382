import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { hostResolver, changeURLPage } from '@/src/helpers';
import { toggleMapShow } from '@/src/redux/actions';
import { AUTHORIZATION, GET_STREET_URL } from '@/constants';

const Body = styled.div`
  padding: 0 0 13px;
  flex: 1;
  border-bottom: 1px solid #ededed;
  cursor: pointer;
`;

const StreetWrapper = styled.div`
  &:last-child {
    & ${Body} {
      border-bottom: none;
    }
  }
`;

const Item = styled.a`
  padding: 13px 0 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const IconWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  max-width: 44px;
  padding-bottom: 13px;
  flex: 1;
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 1.6rem;
  line-height: 24px;
  display: inline-block;
  background-color: #f7f7f7;
  text-align: center;
  border-radius: 100px;
  color: rgba(0, 0, 0, 0.54);
`;

const Name = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.6rem;
  line-height: 19px;
  margin: 0 0 4px;
`;

const Info = styled.p`
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.3rem;
  line-height: 15px;
  margin: 0;
`;

const ShowMoreBtn = styled.button`
  position: absolute;
  top: 0;
  right: 14px;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 28px;
  padding: 0;
`;

const ShowMoreIcon = styled.span`
  font-size: 2.4rem;
  color: rgba(0, 0, 0, 0.54);
`;

const Buildings = styled.div`
  padding: 20px 13px 20px 20px;
  display: flex;
  flex-wrap: wrap;
`;

const Build = styled.button`
  height: 32px;
  width: 44px;
  max-width: 44px;
  flex: 1 1 auto;
  border-radius: 4px;
  background-color: #f7f7f7;
  margin: 0 5px 5px 0;
  display: inline-block;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.9);
  font-size: 1.3rem;
  line-height: 32px;
  text-align: center;
  &:hover,
  &.active {
    background-color: ${({ theme }) => theme.colors.accent};
    color: #fff;
    box-shadow: 0 2px 7px 0 rgba(255, 130, 1, 0.25);
  }
`;

const Street = memo((props) => {
  const {
    t,
    street,
    clickCallback,
    actionToggleMapShow,
    selectedBuilding,
  } = props;
  const initialOpened = selectedBuilding && selectedBuilding.street_id === street.id;
  const [isOpened, showBuildings] = useState(initialOpened);

  const getBuildingOnClick = async ({ buildingStreet, number }) => {
    if (clickCallback) {
      const req = await fetch(`${hostResolver(GET_STREET_URL)}?id=${buildingStreet.id}&number=${number}`, {
        headers: {
          Authorization: AUTHORIZATION,
        }
      });
      const json = await req.json();
      actionToggleMapShow(false);
      if (json) {
        clickCallback({ coords: json.centroid, text: `${buildingStreet.name}, ${number}` });
      }
    } else {
      actionToggleMapShow(true);
      changeURLPage(`street/${buildingStreet.id}?number=${number}`, true);
    }
  };

  return (
    <StreetWrapper>
      <Item
        onClick={(e) => {
          e.preventDefault();
          showBuildings(!isOpened);
        }}
      >
        <IconWrap>
          <Icon className='icon-place' />
        </IconWrap>
        <Body>
          <Name>{street.name}</Name>
          <Info>
            {street.parent_name}
,
            {street.count} 
            {' '}
            {t('houses')}
          </Info>
        </Body>
        <ShowMoreBtn>
          <ShowMoreIcon className={isOpened ? 'icon-expand_less' : 'icon-expand_more'} />
        </ShowMoreBtn>
      </Item>
      {isOpened && (
        <Buildings>
          {street.buildings.map(number => (
            <Build
              onClick={() => {
                getBuildingOnClick({ buildingStreet: street, number });
              }}
              key={number}
              className={selectedBuilding && selectedBuilding.number === number && 'active'}
            >
              {number}
            </Build>
          ))}
        </Buildings>
      )}
    </StreetWrapper>
  );
});

Street.defaultProps = {
  clickCallback: null,
  selectedBuilding: null,
};

Street.propTypes = {
  t: PropTypes.func.isRequired,
  street: PropTypes.object.isRequired,
  selectedBuilding: PropTypes.object,
  clickCallback: PropTypes.func,
  actionToggleMapShow: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  selectedBuilding: state.selected.activeObject,
});

const mapDispatchToProps = dispatch => ({
  actionToggleMapShow: show => dispatch(toggleMapShow(show)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Street)
);
