import React, {
  memo, useEffect, useState, useRef 
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withTranslation } from 'react-i18next';
import Router, { withRouter } from 'next/router';
import { addQueryStringToUrl } from '@/src/helpers';
import {
  getSearchResults, clearSearchResults, toggleMapShow, toggleSearchShow 
} from '@/src/redux/actions';

const Input = styled.input`
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  background: none;
  border: none;
  outline: none;
  padding-right: 40px;
  height: 26px;
  width: 100%;
  color: rgba(0, 0, 0);
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 19px;
  text-overflow: ellipsis;
  &:placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const ClearButton = styled.button`
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 10px;
  margin: auto !important;
  padding: 0;
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100px;
  tex-align: center;
  background-color: #f7f7f7;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const CrossIcon = styled.span`
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.4rem;
`;

const SearchInput = memo((props) => {
  const {
    t,
    isInherited,
    defaultVal,
    actionToggleSearchShow,
    searchShow,
    actionClearSearchResults,
    actionGetSearchResults,
    actionToggleMapShow,
    mapShow,
  } = props;
  const inputRef = useRef({ value: '' });
  const abortRef = useRef(() => {});
  const debounceRef = useRef(() => {});

  const [showClearBtn, toggleClearBtn] = useState(!!defaultVal);

  const clearSearch = () => {
    setTimeout(() => {
      actionClearSearchResults();
    }, 100);
    addQueryStringToUrl({ q: null });
  };

  const clearInput = () => {
    inputRef.current.value = '';
    toggleClearBtn(false);
    clearSearch();
    // actionToggleMapShow(true);
    actionToggleSearchShow(false);
  };

  const mainSearch = (text) => {
    const { abortController } = actionGetSearchResults(text);
    if (!text.length) {
      abortRef.current();
      toggleClearBtn(false);
      clearInput();
      return;
    }
    if (text.length) {
      abortRef.current = abortController;
      toggleClearBtn(true);
      if (mapShow) {
        actionToggleMapShow(false);
      }
    }
    // addQueryStringToUrl({ q: text });
  };

  const handleRouteChange = (url) => {
    actionToggleSearchShow(false);
  };

  const handleOnChange = (text) => {
    if (!searchShow) {
      actionToggleSearchShow(true);
    }
    debounceRef.current(text);
  };

  useEffect(() => {
    debounceRef.current = debounce(mainSearch, 200);
  }, []);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChange);
    if (!isInherited) {
      if (defaultVal) {
        mainSearch(defaultVal);
      }
    }
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <Wrapper>
      <Input
        ref={inputRef}
        placeholder={t('Find')}
        defaultValue={defaultVal}
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
        spellCheck='false'
        onChange={(e) => {
          handleOnChange(e.currentTarget.value);
        }}
      />
      {showClearBtn && (
        <ClearButton onClick={clearInput}>
          <CrossIcon className='icon-close' />
        </ClearButton>
      )}
    </Wrapper>
  );
});

SearchInput.defaultProps = {
  defaultVal: '',
  isInherited: false,
  mapShow: false,
  searchShow: false,
};

SearchInput.propTypes = {
  t: PropTypes.func.isRequired,
  actionToggleMapShow: PropTypes.func.isRequired,
  actionGetSearchResults: PropTypes.func.isRequired,
  actionClearSearchResults: PropTypes.func.isRequired,
  mapShow: PropTypes.bool,
  defaultVal: PropTypes.string,
  actionToggleSearchShow: PropTypes.func.isRequired,
  searchShow: PropTypes.bool,
  isInherited: PropTypes.bool,
};

const mapStateToProps = state => ({
  mapShow: state.map.mapShow,
  searchShow: state.search.searchShow,
});

const mapDispatchToProps = dispatch => ({
  actionToggleMapShow: show => dispatch(toggleMapShow(show)),
  actionGetSearchResults: q => dispatch(getSearchResults(q)),
  actionClearSearchResults: () => dispatch(clearSearchResults()),
  actionToggleSearchShow: show => dispatch(toggleSearchShow(show)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(SearchInput)));
