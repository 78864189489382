import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PlaceComponent from '@/src/components/SidebarViews/Place';
import { MapToolsCtx } from '@/src/contexts';

const Body = styled.div`
  padding: 0 0 13px;
  flex: 1;
  border-bottom: 1px solid #ededed;
`;

const PlaceWrapper = styled.div`
  cursor: pointer;
  &:last-child {
    & ${Body} {
      border-bottom: none;
    }
  }
`;

const Place = (props) => {
  const { place, clickCallback } = props;
  const { mapTools } = useContext(MapToolsCtx);
  return (
    <PlaceWrapper>
      <PlaceComponent clickCallback={clickCallback} item={place} mapTools={mapTools} />
    </PlaceWrapper>
  );
};

Place.defaultProps = {
  clickCallback: null,
};

Place.propTypes = {
  place: PropTypes.object.isRequired,
  clickCallback: PropTypes.func,
};

export default Place;
