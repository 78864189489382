import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import City from '../components/City';
import { MapToolsCtx } from '@/src/contexts';
import { getCity } from '@/src/redux/actions';

const CitiesWrapper = styled.div``;

const Cities = (props) => {
  const { data, clickCallback, actionGetCity } = props;
  const { mapTools } = useContext(MapToolsCtx);
  return (
    <CitiesWrapper>
      {data.map(city => (
        <City clickCallback={clickCallback} key={city.id} actionGetCity={actionGetCity} city={city} mapTools={mapTools} />
      ))}
    </CitiesWrapper>
  );
};

Cities.defaultProps = {
  clickCallback: null,
};

Cities.propTypes = {
  data: PropTypes.array.isRequired,
  actionGetCity: PropTypes.func.isRequired,
  clickCallback: PropTypes.func,
};

Cities.displayName = 'SearchResultsCities';

const mapDispatchToProps = dispatch => ({
  actionGetCity: id => dispatch(getCity(id))
});
export default connect(() => ({}), mapDispatchToProps)(Cities);
