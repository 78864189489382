import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { changeURLPage } from '@/src/helpers';

const Body = styled.div`
  padding: 0 0 13px;
  flex: 1;
  border-bottom: 1px solid #ededed;
`;

const CityWrapper = styled.div`
  cursor: pointer;
  &:last-child {
    & ${Body} {
      border-bottom: none;
    }
  }
`;

const Item = styled.div`
  padding: 13px 0 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const IconWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  max-width: 44px;
  flex: 1;
  padding: 0 0 13px;
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 1.6rem;
  line-height: 24px;
  display: inline-block;
  background-color: #f7f7f7;
  text-align: center;
  border-radius: 100px;
  color: rgba(0, 0, 0, 0.54);
`;

const Name = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.6rem;
  line-height: 19px;
  margin: 0 0 4px;
`;

const Info = styled.p`
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.3rem;
  line-height: 15px;
  margin: 0;
`;

const City = (props) => {
  const { city, clickCallback, actionGetCity } = props;
  // useEffect(() => () => mapTools.clearMapPlaceMarker(), []);
  const openCity = async () => {
    if (clickCallback) {
      const cityResp = await actionGetCity(city.id);
      const res = cityResp.payload;
      if (res) {
        clickCallback({ coords: res.centroid, text: `${res.name}` });
      }
    } else {
      changeURLPage(`city/${city.id}`, true);
    }
  };

  // useEffect(() => {
  //   if (selectedCity.id) {
  //     let place = {
  //       point: { ...selectedCity.centroid },
  //       brand: `${selectedCity.name}`,
  //     };
  //     mapTools.setPlaceMarker(place, true);
  //   }
  // }, [selectedCity, selectedCity.id]);

  return (
    <CityWrapper onClick={openCity}>
      <Item>
        <IconWrap>
          <Icon className='icon-place' />
        </IconWrap>
        <Body>
          <Name>{city.name}</Name>
          <Info>{city.is_in}</Info>
        </Body>
      </Item>
    </CityWrapper>
  );
};

City.defaultProps = {
  clickCallback: null,
};

City.propTypes = {
  city: PropTypes.object.isRequired,
  actionGetCity: PropTypes.func.isRequired,
  clickCallback: PropTypes.func,
};

export default City;
