import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { changeURLPage } from '@/src/helpers';
import { clearSearchResults, getCompanyBranches, clearCompanyBranches } from '@/src/redux/actions';
import PlaceBranches from './PlaceBranches';

const Body = styled.div`
  padding: 0 57px 13px 0;
  flex: 1;
  border-bottom: 1px solid #ededed;
  min-height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PlaceWrapper = styled.div`
  &:last-child {
    & ${Body} {
      border-bottom: none;
    }
  }
  cursor: pointer;
  border-left: 3px solid #fff;
  &:hover,
  &.is-expanded {
    background-color: rgba(0, 0, 0, 0.02);
    border-left: 3px solid ${({ theme }) => theme.colors.accent};
  }
`;

const Item = styled.div`
  padding: 13px 0 0 20px;
  margin-left: -3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const IconWrap = styled.div`
  display: inline-block;
  vertical-align: middle;
  max-width: 44px;
  flex: 1;
  padding: 0 0 13px;
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 1.6rem;
  line-height: 24px;
  display: inline-block;
  background-color: #f7f7f7;
  text-align: center;
  border-radius: 100px;
  color: rgba(0, 0, 0, 0.54);
  &.icon-pins-group {
    line-height: 25px;
  }
`;

const Name = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.6rem;
  line-height: 19px;
  margin: 0;
`;

const Info = styled.p`
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.3rem;
  line-height: 15px;
  margin: 4px 0 0;
`;

const ShowMoreBtn = styled.button`
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  transform: rotate(-90deg);
  margin: auto;
  padding: 0;
  width: 24px;
  height: 28px;
`;

const ShowMoreIcon = styled.span`
  font-size: 2.4rem;
  color: rgba(0, 0, 0, 0.54);
`;

const Place = (props) => {
  const [isExpanded, expandBranches] = useState(false);
  const {
    t,
    item,
    companyBranches,
    categoryUrl,
    clickCallback,
    emptySearchResults,
    actionGetCompanyBranches,
    actionClearCompanyBranches
  } = props;
  const isGroup = item.braches_count > 1 || item.branches_count > 1;
  let itemInfo = item.address_text;
  if (isGroup) {
    itemInfo = `${item.category_titles.ru}, ${item.braches_count || item.branches_count} ${t('places')}`;
  }

  const openBranches = async (companyId, catUrl) => {
    changeURLPage(`places/${catUrl ? `${catUrl}/` : ''}${companyId}`, true);
  };

  const openPlace = (clickedPlace) => {
    const {
      point, brand, braches_count, branches_count, address_text, company_id, branch_id, category_url
    } = clickedPlace;
    const catUrl = category_url ? category_url.en : categoryUrl;
    const isPlaceGroup = braches_count > 1 || branches_count > 1;
    if (clickCallback) {
      if (!isPlaceGroup) {
        clickCallback({ coords: point, text: `${brand}, ${address_text}` });
      } else {
        actionClearCompanyBranches();
        actionGetCompanyBranches(company_id, catUrl);
        expandBranches(!isExpanded);
      }
      return;
    }
    if (isPlaceGroup) {
      openBranches(company_id, catUrl);
      return;
    }
    emptySearchResults();
    const isBranch = !!branch_id;
    changeURLPage(
      `places/${catUrl ? `${catUrl}/` : ''}${company_id}${
        isBranch && branch_id ? `/${branch_id}` : ''
      }`,
      true
    );
  };

  return (
    <Fragment>
      <PlaceWrapper className={isExpanded && 'is-expanded'}>
        <Item>
          <IconWrap>
            <Icon className={`icon-${isGroup ? 'pins-group' : 'place'}`} />
          </IconWrap>
          <Body
            onClick={() => openPlace(item)}
          >
            <Name>{item.brand}</Name>
            {itemInfo && <Info>{itemInfo}</Info>}
            {isGroup && (
              <ShowMoreBtn>
                <ShowMoreIcon className={`icon-${isExpanded ? 'expand_less' : 'expand_more'}`} />
              </ShowMoreBtn>
            )}
          </Body>
        </Item>
      </PlaceWrapper>
      {isExpanded && (
        <PlaceBranches
          items={(companyBranches && companyBranches.points) || []}
          openPlace={openPlace}
        />
      )}
    </Fragment>
  );
};

Place.defaultProps = {
  clickCallback: null,
  companyBranches: null,
  categoryUrl: ''
};

Place.propTypes = {
  t: PropTypes.func.isRequired,
  categoryUrl: PropTypes.string,
  clickCallback: PropTypes.func,
  emptySearchResults: PropTypes.func.isRequired,
  actionGetCompanyBranches: PropTypes.func.isRequired,
  actionClearCompanyBranches: PropTypes.func.isRequired,
  companyBranches: PropTypes.object,
  item: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  companyBranches: state.selected.branches,
});

const mapDispatchToProps = dispatch => ({
  emptySearchResults: () => dispatch(clearSearchResults()),
  actionGetCompanyBranches: (companyId, categoryUrl) => dispatch(getCompanyBranches(companyId, categoryUrl)),
  actionClearCompanyBranches: () => dispatch(clearCompanyBranches())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Place));
