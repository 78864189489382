import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { hostResolver } from '@/src/helpers';
import SidebarHeader from '@/src/components/Sidebar/Header';
import { AUTHORIZATION, ADD_PLACE_ERROR_URL } from '@/constants';

const Wrap = styled.div`
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1005;
  background: #f5f5f5;
`;

const ContetntWrap = styled.div`
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(94, 94, 94, 0.21);
`;

const Header = styled(SidebarHeader)`
  height: 50px;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent};
`;

const BackButton = styled.button`
  flex: 0 1 auto;
  padding: 0;
`;

const ArrowBack = styled.span`
  font-size: 2.4rem;
  color: #fff;
`;

const CloseButton = styled.button`
  flex: 0 1 auto;
  padding: 0;
`;

const CloseIcon = styled.span`
  font-size: 2.4rem;
  color: #fff;
`;

const Title = styled.p`
  flex: 1 1 auto;
  color: #fff;
  font-size: 1.6rem;
  line-height: 19px;
  margin: 0 20px 0 17px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
`;

const FormWrap = styled.div`
  padding: 20px;
  background-color: #fff;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  span {
    color: ${({ valid }) => (valid ? 'rgba(0,0,0,0.54)' : '#FF3E3E')};
  }
  textarea,
  input {
    border-bottom-color: ${({ valid }) => (valid ? '#EDEDED' : '#FF3E3E')};
  }
`;

const TextAreaIcon = styled.span`
  padding-right: 20px;
  font-size: 2.2rem;
`;

const CommentArea = styled.textarea`
  font-size: 1.6rem;
  border: none;
  border-bottom: 1px solid #ededed;
  width: 100%;
  color: #000;
  opacity: 0.8;
  resize: none;
  min-height: 130px;
  overflow-y: auto;
  padding: 3px 0 0;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &::placeholder {
    opacity: 0.4;
    color: #000000;
    font-size: 1.4rem;
  }
`;

const RowIcon = styled.span`
  padding: 16px 20px 0 0;
  font-size: 2.2rem;
  color: rgba(0, 0, 0, 0.54);
`;

const FormInput = styled.input`
  padding: 20px 0;
  font-size: 1.6rem;
  border: none;
  border-bottom: 1px solid #ededed;
  width: 100%;
  color: #000;
  opacity: 0.8;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  &::placeholder {
    opacity: 0.4;
    color: #000000;
    font-size: 1.4rem;
  }
`;

const SubmitWrap = styled.div`
  text-align: right;
`;

const SubmitText = styled.p`
  opacity: 0.4;
  margin: 20px 0;
  color: #000;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
`;

const SubmitBtn = styled.button`
  border: none;
  color: #fff;
  border-radius: 22px;
  padding: 9px 20px 11px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.accent};
`;

const ReqSend = styled.div`
  display: block;
  height: 144px;
  width: 144px;
  border-radius: 50%;
  margin: 72px auto 30px;
  background: url(/static/images/send.svg) center no-repeat #f7f7f7;
`;

const ReqText = styled.div`
  font-size: 1.3rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  line-height: 15px;
  padding: 0 0 72px;
`;

class AddPlace extends Component {
  constructor() {
    super();
    this.state = {
      request: false,
      comment: { value: '', valid: true },
      company: { value: '', valid: true },
      addres: { value: '', valid: true },
      phone: { value: '', valid: true },
      website: { value: '', valid: true },
      mail: { value: '', valid: true },
      contact: { value: '', valid: true },
    };
    this.regExp = {
      phone: /^[0-9\-+]{9,15}$/,
      // eslint-disable-next-line max-len
      mail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  }

  testRegExp = (field, value) => this.regExp[field].test(value);

  setInputData = (field, value) => {
    if (value.length >= 250) {
      this.setState(prevState => ({ [field]: { ...prevState[field], valid: true } }));
    } else {
      this.setState({ [field]: { value, valid: true } });
    }
  };

  checkRequired = (field) => {
    const { state } = this;
    if (state[field].value.length === 0) {
      this.setState(prevState => ({ [field]: { ...prevState[field], valid: false } }));
      return false;
    }
    return true;
  };

  handleForm = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'comment':
      case 'company':
      case 'addres':
      case 'website':
      case 'contact':
        this.setInputData(name, value);
        break;
      case 'mail':
        if (this.testRegExp(name, value)) {
          this.setState({ [name]: { value, valid: true } });
        } else {
          this.setState({ [name]: { value, valid: false } });
        }
        break;
      case 'phone':
        if (this.testRegExp(name, value)) {
          this.setState({ [name]: { value, valid: true } });
        } else {
          this.setState({ [name]: { value, valid: false } });
        }
        break;
      default:
    }
  };

  submitForm = async () => {
    const { state } = this;
    const { addError, userAuth } = this.props;
    let formValid = true;
    let submitString = '';
    let submitMail = null;
    Object.keys(this.state).map((field) => {
      switch (field) {
        case 'comment':
          if (this.checkRequired(field)) {
            submitString = submitString.concat(state[field].value, ', ');
          } else {
            formValid = false;
          }
          break;
        case 'company':
        case 'addres':
          if (addError) break;
          if (this.checkRequired(field)) {
            submitString = submitString.concat(state[field].value, ', ');
          } else {
            formValid = false;
          }
          break;
        case 'website':
          if (addError) break;
          submitString = submitString.concat(state[field].value, ', ');
          break;
        case 'contact':
          submitString = submitString.concat(state[field].value, ', ');
          break;
        case 'phone':
          if (addError) break;
          if (this.testRegExp(field, state[field].value)) {
            submitString = submitString.concat(state[field].value, ', ');
          } else {
            this.setState(prevState => ({ [field]: { ...prevState[field], valid: false } }));
            formValid = false;
          }
          break;
        case 'mail':
          if (addError) break;
          if (this.testRegExp(field, state[field].value)) {
            submitMail = state[field].value;
          } else {
            this.setState(prevState => ({ [field]: { ...prevState[field], valid: false } }));
            formValid = false;
          }
          break;
        default:
      }
    });

    if (!formValid) return;
    const resp = await fetch(`${hostResolver(ADD_PLACE_ERROR_URL)}?auth=${userAuth}`, {
      method: 'POST',
      headers: {
        Authorization: AUTHORIZATION
      },
      body: JSON.stringify({
        message: submitString,
        email: submitMail,
      }),
    });
    const jsonResp = await resp.json();
    if (jsonResp.success) this.setState({ request: true });
  };

  render() {
    const { t, BackBtn, addError } = this.props;
    const {
      request, comment, company, addres, phone, website, mail, contact
    } = this.state;
    return (
      <Wrap>
        <ContetntWrap>
          <Header>
            <BackButton onClick={BackBtn}>
              <ArrowBack className='icon-arrow_back' />
            </BackButton>
            <Title>{addError ? t('feedback') : t('add place')}</Title>
            <CloseButton onClick={BackBtn}>
              <CloseIcon className='icon-close' />
            </CloseButton>
          </Header>
          <FormWrap>
            {!request ? (
              <Fragment>
                {addError ? (
                  <Fragment>
                    <Row valid={comment.valid}>
                      <TextAreaIcon className='icon-insert_comment' />
                      <CommentArea
                        placeholder={t('comment')}
                        name='comment'
                        value={comment.value}
                        onChange={e => this.handleForm(e)}
                      />
                    </Row>
                    <Row valid={contact.valid}>
                      <RowIcon className='icon-ring_volume' />
                      <FormInput
                        placeholder={t('your contact')}
                        name='contact'
                        value={contact.value}
                        onChange={e => this.handleForm(e)}
                      />
                    </Row>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Row valid={comment.valid}>
                      <TextAreaIcon className='icon-insert_comment' />
                      <CommentArea
                        placeholder={t('comment')}
                        name='comment'
                        value={comment.value}
                        onChange={e => this.handleForm(e)}
                      />
                    </Row>
                    <Row valid={company.valid}>
                      <RowIcon className='icon-flag' />
                      <FormInput
                        placeholder={t('company name')}
                        name='company'
                        value={company.value}
                        onChange={e => this.handleForm(e)}
                      />
                    </Row>
                    <Row valid={addres.valid}>
                      <RowIcon className='icon-place' />
                      <FormInput
                        placeholder={t('addres')}
                        name='addres'
                        value={addres.value}
                        onChange={e => this.handleForm(e)}
                      />
                    </Row>
                    <Row valid={phone.valid}>
                      <RowIcon className='icon-ring_volume' />
                      <FormInput
                        placeholder={t('phone number')}
                        name='phone'
                        value={phone.value}
                        onChange={e => this.handleForm(e)}
                      />
                    </Row>
                    <Row valid={website.valid}>
                      <RowIcon className='icon-language' />
                      <FormInput
                        placeholder={t('website')}
                        name='website'
                        value={website.value}
                        onChange={e => this.handleForm(e)}
                      />
                    </Row>
                    <Row valid={mail.valid}>
                      <RowIcon className='icon-email' />
                      <FormInput
                        placeholder={t('e-mail')}
                        name='mail'
                        value={mail.value}
                        onChange={e => this.handleForm(e)}
                      />
                    </Row>
                    <Row valid={contact.valid}>
                      <RowIcon className='icon-mobile_friendly' />
                      <FormInput
                        placeholder={t('your contact')}
                        name='contact'
                        value={contact.value}
                        onChange={e => this.handleForm(e)}
                      />
                    </Row>
                  </Fragment>
                )}
                <SubmitWrap>
                  <SubmitText>{t('req add place')}</SubmitText>
                  <SubmitBtn onClick={() => {
                    this.submitForm();
                  }}
                  >
                    {t('submit req')}
                  </SubmitBtn>
                </SubmitWrap>
              </Fragment>
            ) : (
              <Fragment>
                <ReqSend />
                <ReqText dangerouslySetInnerHTML={{ __html: t('req send') }} />
              </Fragment>
            )}
          </FormWrap>
        </ContetntWrap>
      </Wrap>
    );
  }
}

AddPlace.propTypes = {
  t: PropTypes.func.isRequired,
  BackBtn: PropTypes.func.isRequired,
  addError: PropTypes.bool.isRequired,
  userAuth: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  userAuth: state.map.userAuth,
});

export default connect(mapStateToProps)(withTranslation()(AddPlace));
