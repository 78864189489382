import React, {
  useState, useEffect, memo, useRef 
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { changeURLPage, queryStringToUrl } from '@/src/helpers';
import { clearSearchResults } from '@/src/redux/actions';
import Streets from './containers/Streets';
import Cities from './containers/Cities';
import Places from './containers/Places';

const Wrapper = styled.div`
  border-radius: 0 0 10px 10px;
  background-color: #ffffff;
  overflow: ${({ searchRout }) => (searchRout ? 'auto' : 'hidden')};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(94, 94, 94, 0.21);
  display:none;
  &.visible {
    display:block;
    z-index: 333;
    position: relative;
    ${({ searchRout }) => searchRout && (`
      position: absolute;
      width: calc(100% - 16px);
  `)}
  }
  &.has-results {
    height: calc(100% - 210px);
  }
  &.has-results-full {
    height: calc(100% - 65px);
    ${({ theme }) => theme.onSM(`
      height: calc(100% - 50px);
    `)}
  }
`;

const Tabs = styled.div`
  padding: 17px 20px;
  display: flex;
  justify-content: space-between;
`;

const Tab = styled.button`
  text-transform: capitalize;
  padding: 7px 20px;
  width: 33.33%;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.43px;
  line-height: 16px;
  text-align: center;
  border-radius: 21px;
  background-color: #f7f7f7;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:not([disabled]) {
    &:hover,
    &.is-active {
      background-color: ${({ theme }) => theme.colors.accent};
      color: #fff;
    }
  }
`;

const TabContent = styled.div`
  position: relative;
  display: inline;
`;

const Counter = styled.span`
  position: relative;
  top: -4px;
  right: -2px;
  font-size: 1.0rem;
  font-weight: bold;
  letter-spacing: -0.4px;
  line-height: 12px;
`;

const Results = styled.div`
  height: calc(100% - 65px);
  overflow: auto;
`;

const reformatData = (data) => {
  let hasResTab = '';
  const results = {
    streets: data.filter(item => item.type === 'street'),
    cities: data.filter(item => item.type === 'city'),
    places: data.filter(item => item.type === 'object' || item.type === 'place'),
  };
  if (results.streets.length) {
    hasResTab = 'streets';
  } else if (results.cities.length) {
    hasResTab = 'cities';
  } else if (results.places.length) {
    hasResTab = 'places';
  }
  return { results, hasResTab };
};

const SearchResults = memo((props) => {
  const tickGoToSelected = useRef(null);
  const {
    t,
    data,
    onlyResults,
    clickCallback,
    searchShow,
    actionClearSearchResults,
    storeSearchResults,
    storeSearchSelected,
    router,
    searchRout
  } = props;
  const { query: { pageName } } = router;
  let searchData = storeSearchResults;
  if (onlyResults) {
    searchData = data;
  }
  const {
    results: { streets, cities, places },
    hasResTab,
  } = reformatData(searchData || []);

  const [currentTab, changeTab] = useState(hasResTab);
  const hasRes = streets.length || cities.length || places.length;
  
  useEffect(() => {
    changeTab(hasResTab);
  }, [streets.length, cities.length, places.length]);

  useEffect(() => {
    clearTimeout(tickGoToSelected.current);
    if (storeSearchSelected) {
      tickGoToSelected.current = setTimeout(() => {
        const { street_id, number } = storeSearchSelected;
        if (pageName === 'routing' || !storeSearchSelected) {
          actionClearSearchResults();
          return;
        }
        changeURLPage(`street/${street_id}${queryStringToUrl({ number })}`, true);
        clearTimeout(tickGoToSelected.current);
      }, 1000);
    }
  }, [storeSearchSelected]);
  return (
    <Wrapper
      className={classNames({
        'visible': searchShow || (!!searchData && !pageName),
        'has-results': hasRes,
        'only-res': onlyResults,
        'has-results-full': hasRes && !onlyResults,
      })}
      searchRout={searchRout}
    >
      {hasRes && (
        <Tabs>
          <Tab
            onClick={() => changeTab('streets')}
            className={currentTab === 'streets' && 'is-active'}
            disabled={!streets.length}
          >
            <TabContent>
              {t('streets')}
              <Counter>{streets.length}</Counter>
            </TabContent>
          </Tab>
          <Tab
            onClick={() => changeTab('cities')}
            className={currentTab === 'cities' && 'is-active'}
            disabled={!cities.length}
          >
            <TabContent>
              {t('cities')}
              <Counter>{cities.length}</Counter>
            </TabContent>
          </Tab>
          <Tab
            onClick={() => changeTab('places')}
            className={currentTab === 'places' && 'is-active'}
            disabled={!places.length}
          >
            <TabContent>
              {t('places')}
              <Counter>{places.length}</Counter>
            </TabContent>
          </Tab>
        </Tabs>
      )}
      <Results>
        {currentTab === 'streets' && (
          <Streets
            clickCallback={clickCallback}
            data={streets}
            selected={storeSearchSelected}
          />
        )}
        {currentTab === 'cities' && (
          <Cities
            clickCallback={clickCallback}
            data={cities}
          />
        )}
        {currentTab === 'places' && (
          <Places
            clickCallback={clickCallback}
            data={places}
          />
        )}
      </Results>
    </Wrapper>
  );
});

SearchResults.defaultProps = {
  clickCallback: null,
  onlyResults: false,
  data: [],
  storeSearchResults: [],
  storeSearchSelected: null,
  searchShow: false,
  searchRout: false,
};

SearchResults.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array,
  router: PropTypes.object.isRequired,
  actionClearSearchResults: PropTypes.func.isRequired,
  storeSearchResults: PropTypes.array,
  storeSearchSelected: PropTypes.object,
  clickCallback: PropTypes.func,
  onlyResults: PropTypes.bool,
  searchShow: PropTypes.bool,
  searchRout: PropTypes.bool,
};

const mapStateToProps = state => ({
  storeSearchResults: state.search.results,
  storeSearchSelected: state.search.selected,
});

const mapDispatchToProps = dispatch => ({
  actionClearSearchResults: () => dispatch(clearSearchResults()),
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResults)));
