import styled from 'styled-components';

const SidebarHeader = styled.div`
  background: #fff;
  /* width: 380px; */
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(94, 94, 94, 0.21);
  padding: 10.5px 20px;
  &.is-search {
    border-radius: 10px 10px 0 0;
  }
`;

export default SidebarHeader;
