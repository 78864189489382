import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MapToolsCtx } from '@/src/contexts';
import Street from '../components/Street';

const StreetsWrapper = styled.div``;

const Streets = memo((props) => {
  const { data, clickCallback } = props;
  return (
    <StreetsWrapper>
      <MapToolsCtx.Consumer>
        {({ mapTools }) => data.map(street => (
          <Street
            mapTools={mapTools}
            clickCallback={clickCallback}
            key={street.id + street.count}
            street={street}
          />
        ))
        }
      </MapToolsCtx.Consumer>
    </StreetsWrapper>
  );
});

Streets.defaultProps = {
  clickCallback: null,
};

Streets.propTypes = {
  data: PropTypes.array.isRequired,
  clickCallback: PropTypes.func,
};

Streets.displayName = 'SearchResultsStreet';

export default Streets;
